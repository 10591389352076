<template>
  <div class="s-l-layout__progress" id="mobprogress">
    <ol class="s-timeline" aria-label="Ukazatal průběhu objednávky">
      <li class="s-timeline__item">
        <span class="s-u-visualy-hidden"> Dokončený krok: </span>
        <a href="javascript:void(0)" class="s-timeline__link" title="Výběr lokality">
          <span class="s-timeline__text"> Výběr lokality </span>
        </a>
      </li>
      <li class="s-timeline__item">
        <span class="s-u-visualy-hidden"> Dokončený krok: </span>
        <a href="javascript:void(0)" class="s-timeline__link" title="Výběr produktu">
          <span class="s-timeline__text"> Výběr produktu </span>
        </a>
      </li>
      <li class="s-timeline__item">
        <span class="s-u-visualy-hidden"> Dokončený krok: </span>
        <a href="javascript:void(0)" class="s-timeline__link">
          <span class="s-timeline__text" title="Výběr termínu"> Výběr termínu </span>
        </a>
      </li>
      <li class="s-timeline__item s-timeline__item--current">
        <span class="s-timeline__link">
          <span class="s-u-visualy-hidden"> Aktuální krok: </span>
          <span class="s-timeline__text"> Identifikace </span>
        </span>
      </li>
    </ol>
  </div>

  <div class="s-l-layout__left-side s-u-order-1" id="leftside">
    <h2 class="s-h s-h--secondary">Identifikační údaje</h2>
    <div class="s-form">
      <div class="s-tabs s-tabs--id" id="jsTab">
        <div class="s-tabs__list" role="tablist" aria-label="Výběr osoby k vyplnění">
          <button
            v-for="(customer, index) in customers"
            :key="index"
            class="s-tabs__tab"
            :class="{ 's-tabs__tab--active': tab === index + 1 }"
            role="tab"
            aria-selected="true"
            :aria-controls="`table-${index + 1}`"
            type="button"
            @click.prevent="tab = index + 1"
          >
            <span class="s-u-visualy-hidden">
              <!-- Text menit podle toho, zda je osoba jiz vyplnena nebo ne -->
              Zkontrolovat již vyplněné údaje / Vyplnit údaje
            </span>
            <span class="d-block" :class="{ 's-tabs__check': valid[index], 's-tabs__fail': !valid[index] }"> </span>
            <span class="s-tabs__date pl-3"> Osoba&nbsp;{{ index + 1 }} </span>
          </button>
        </div>

        <div class="s-form-box">
          <CustomerFormComponent
            v-show="tab === index + 1"
            v-for="(customer, index) in customers"
            :key="index"
            :index="index"
            :customer="customer"
            @is-valid="valid[index] = $event"
          ></CustomerFormComponent>

          <div class="s-form__bottom">
            <div class="mb-5">
              <input type="checkbox" name="agree" id="agree" class="s-check" v-model="agreement" />
              <label for="agree" class="s-check__label">
                Uděluji dobrovolný Souhlas se zpracováním osobních údajů a prohlašuji, že jsem se seznámil(a) s
                dokumentem Zásady ochrany osobních údajů.*
              </label>
            </div>
            <div v-if="!me" class="mb-5">
              <span class="d-flex">
                <input type="checkbox" name="create" id="create" class="s-check" v-model="createUser" />
                <label for="create" class="s-check__label">
                  Vytvořit uživatelský účet. Aktivačný odkaz bude zaslán e-mailem.
                </label>
                <span class="s-tooltip ml-3">
                  <button
                    class="s-tooltip__btn s-tooltip__btn--question"
                    aria-describedby="what"
                    aria-expanded="false"
                    aria-label="Vytvořit uživatelské konto a zaslat heslo e-mailem"
                  >
                    <span class="s-u-visualy-hidden"> ? </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 3.7 5.3"
                      width="6"
                      height="8.6"
                      aria-hidden="true"
                      focusable="false"
                      role="img"
                    >
                      <path
                        fill="currentColor"
                        d="M1.8 0C1.1 0 .4.3 0 .9c0 .2 0 .3.1.4l.4.3c.1.1.3.1.3 0 .2-.3.5-.5.9-.5.3 0 .7.2.7.5 0 .2-.2.4-.5.5-.3.2-.8.5-.8 1.1v.1c0 .1.1.2.2.2h.8c.1 0 .2-.1.2-.2 0-.4 1.3-.5 1.3-1.7C3.6.7 2.8 0 1.8 0zm-.1 3.9c-.4 0-.7.3-.7.7s.3.7.7.7.7-.3.7-.7c.1-.4-.3-.7-.7-.7z"
                      />
                    </svg>
                  </button>
                  <span class="s-tooltip__tooltip" id="what" role="tooltip">
                    Nejaky text, ktery vysvetli co a jak se deje
                  </span>
                </span>
              </span>
            </div>
            <span class="position-relative">
              <button
                class="s-btn s-btn--submit"
                :class="{ 's-tooltip__btn': submitButtonDisabled }"
                :disabled="submitButtonDisabled"
                @click="resolveForm"
              >
                Dokončit rezervaci
              </button>
              <span class="s-tooltip__tooltip" id="what" role="tooltip"> Vyplňte prosím všechna povinná pole </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CustomerFormComponent from '../../components/customers/CustomersFormComponent';

import { XiteApiService } from '../../services/xite.api.service';

export default {
  name: 'ReservationInfo',

  components: {
    CustomerFormComponent,
  },

  data() {
    return {
      tab: 1,
      agreement: false,
      createUser: false,
      valid: [],
      customerIds: [],
    };
  },

  computed: {
    ...mapGetters({
      formData: 'orders/getFormData',
      me: 'me/getMe',
    }),

    customers() {
      return this.formData.customers;
    },

    submitButtonDisabled() {
      return !this.agreement || !this.valid.reduce((acc, curr) => acc && curr, true);
    },
  },

  methods: {
    ...mapActions({ createOrder: 'orders/createOrder' }),

    createCustomer() {
      let promises = [];
      return new Promise(resolve => {
        this.formData.customers.forEach(() => {
          promises.push(
            XiteApiService.get('Insert', {
              type: 'customer',
            }).then(file => {
              this.customerIds.push(file.Get('file'));
            })
          );
        });
        Promise.all(promises).then(response => {
          resolve(response);
        });
      });
    },

    createReservation(customerId) {
      XiteApiService.get('Reserve', {
        customer: customerId,
        instance: process.env.VUE_APP_INSTANCE_FILE,
        location: this.formData.locationId,
        product: this.formData.productId,
        date: this.formData.reservedDay,
        time: this.formData.reservedTime,
      }).then(() => {
        this.$router.push({ name: 'ReservationCompleted' });
      });
    },

    submitForm() {
      if (this.me) {
        this.createReservation(this.me.attributes.id);
        return false;
      }

      let promises = [];
      return new Promise(resolve => {
        this.createCustomer().then(() => {
          for (let i = 0; i < this.customerIds.length; i++) {
            const customerId = this.customerIds[i];
            const data = this.formData.customers[i];
            promises.push(
              XiteApiService.get('SetItems', {
                file: customerId,
                name: data.name,
                surname: data.surname,
                birthdate: data.birthdate,
                nationalid: data.nationalid,
                nationality: data.nationality,
                insurance: data.insurance,
                phone: data.phone,
                email: data.email,
                street: data.street,
                city: data.city,
                zip: data.zip,
                country: data.country,
                username: data.email,
                consent_privacy: 1,
                consent_registration: this.createUser ? 1 : 0,
              }).then(() => {
                this.createReservation(customerId);
              })
            );
          }
          Promise.all(promises).then(response => {
            resolve(response);
          });
        });
      });
    },

    resolveForm() {
      this.submitForm().then(() => {
        console.log('ok');
      });
    },
  },
};
</script>

<style scoped>

</style>
